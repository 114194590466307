@import 'variables';

.note-editor .megadraft-editor {

  .DraftEditor-root,
  .toolbar {
    color: $txtColor; }

  .paragraph + .paragraph {
      margin-top: 20px; } }
