@import 'variables';
@import 'bootstrap';
@import 'background';

body {
  margin: 0;
  font-family: 'Prompt', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

.app {
  width: 100%;
  height: 100%;
  // background-color: $backgroundColor
  color: $txtColor; }
