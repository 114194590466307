.animated-background {
  background: linear-gradient(270deg, #f7ab11, #0dff21);
  background-size: 400% 400%;
  -webkit-animation: background-animation 35s ease infinite;
  -moz-animation: background-animation 35s ease infinite;
  -o-animation: background-animation 35s ease infinite;
  animation: background-animation 35s ease infinite;
  height: 100vh;
  padding-top: 2vh; }

@-webkit-keyframes background-animation {
  0% {
    background-position: 0% 14%; }
  50% {
    background-position: 100% 87%; }
  100% {
    background-position: 0% 14%; } }

@-moz-keyframes background-animation {
  0% {
    background-position: 0% 14%; }
  50% {
    background-position: 100% 87%; }
  100% {
    background-position: 0% 14%; } }

@-o-keyframes background-animation {
  0% {
    background-position: 0% 14%; }
  50% {
    background-position: 100% 87%; }
  100% {
    background-position: 0% 14%; } }

@keyframes background-animation {
  0% {
    background-position: 0% 14%; }
  50% {
    background-position: 100% 87%; }
  100% {
    background-position: 0% 14%; } }
