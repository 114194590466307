@import 'variables';

.todo {
  &.complete .drag,
  &.complete {
    color: $txtInactive; }

  &.uncomplete .drag,
  &.uncomplete {
    color: $txtColor; }

  .drag {
    height: 2rem; }

  .content p {
    margin: 0; }

  code {
    background-color: #424242;
    padding: 1px 4px; }

  em {
    font-style: italic; }

  .actions {
    float: right; } }

.ui.input.add-todo {
  width: 100%;
  padding: 12px 0;

  &>input,
  &>input:focus {
    color: $txtColor; } }
